import request from '@/utils/request'
import { param2Obj } from '@/utils'

// 购物车
export function getCartList(data) {
  return request({
    url: '/cart/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createCart(data) {
  return request({
    url: '/cart/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteCart(data) {
  return request({
    url: '/cart/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function totalCart(data) {
  return request({
    url: '/cart/calprice',
    method: 'post',
    data: param2Obj(data)
  })
}

// 订单
export function fetchAddress(data) {
  return request({
    url: '/member/defaddress',
    method: 'post',
    data: param2Obj(data)
  })
}

export function confirmOrder(data) {
  return request({
    url: '/order/confirm',
    method: 'post',
    data: param2Obj(data)
  })
}

export function createOrder(data) {
  return request({
    url: '/order/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function paymentOrder(data) {
  return request({
    url: '/order/payment',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getOrderList(data) {
  return request({
    url: '/wap/order/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchOrder(data) {
  return request({
    url: '/wap/order/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function cancelOrder(data) {
  return request({
    url: '/wap/order/cancel',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteOrder(data) {
  return request({
    url: '/wap/order/delete',
    method: 'post',
    data: param2Obj(data)
  })
}

export function confirmReceipt(data) {
  return request({
    url: '/wap/order/receipt/confirm',
    method: 'post',
    data: param2Obj(data)
  })
}

export function paymentType(data) {
  return request({
    url: '/order/pay/ways',
    method: 'post',
    data: param2Obj(data)
  })
}

export function orderStatistics(data) {
  return request({
    url: '/wap/order/status/statistics',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLogistic(data) {
  return request({
    url: '/wap/order/goods/delivery/info',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getStatus(data) {
  return request({
    url: '/wap/order/pay/status',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchEvaluateGoods(data) {
  return request({
    url: '/wap/order/evaluation/show',
    method: 'post',
    data: param2Obj(data)
  })
}

export function publishEvaluate(data) {
  return request({
    url: '/wap/order/evaluation/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchEvaluateList(data) {
  return request({
    url: '/wap/order/evaluation/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function anonymityEvaluate(data) {
  return request({
    url: '/wap/order/evaluation/showNicknames',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundGoods(data) {
  return request({
    url: '/wap/refund/goods/info',
    method: 'post',
    data: param2Obj(data)
  })
}

export function refundApply(data) {
  return request({
    url: '/wap/refund/goods/apply',
    method: 'post',
    data: param2Obj(data)
  })
}

export function refundReason(data) {
  return request({
    url: '/wap/common/params',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundList(data) {
  return request({
    url: '/wap/refund/goods/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchRefundInfo(data) {
  return request({
    url: '/wap/refund/goods/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchLogistics(data) {
  return request({
    url: '/wap/common/params',
    method: 'post',
    data: param2Obj(data)
  })
}

export function refundLogistics(data) {
  return request({
    url: '/wap/refund/goods/doLogistics',
    method: 'post',
    data: param2Obj(data)
  })
}

export function deleteRefundRecord(data) {
  return request({
    url: '/wap/refund/goods/delete',
    method: 'post',
    data: param2Obj(data)
  })
}
