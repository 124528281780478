import request from '@/utils/request'
import { param2Obj } from '@/utils'

// H5-店铺
export function fetchShopView(data) {
  return request({
    url: '/wap/store/index',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getViewGoods(data) {
  return request({
    url: '/wap/store/goods/list',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getCoupons(data) {
  return request({
    url: '/wap/store/coupons',
    method: 'post',
    data: param2Obj(data)
  })
}

export function couponReceive(data) {
  return request({
    url: '/wap/coupon/receive',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchShopInfo(data) {
  return request({
    url: '/wap/store/info',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getShopGoods(data) {
  return request({
    url: '/wap/store/goods/page',
    method: 'post',
    data: param2Obj(data)
  })
}

export function fetchIntroduction(data) {
  return request({
    url: '/wap/store/introduction',
    method: 'post',
    data: param2Obj(data)
  })
}

// 商品详情
export function fetchGoods(data) {
  return request({
    url: '/goods/detail',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getGoodsRecommends(data) {
  return request({
    url: '/goods/recommends',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getGoodsComments(data) {
  return request({
    url: '/goods/comments',
    method: 'post',
    data: param2Obj(data)
  })
}

export function getGoodsConsults(data) {
  return request({
    url: '/goods/consults',
    method: 'post',
    data: param2Obj(data)
  })
}

// 收藏店铺/直播/商品
export function onCollect(data) {
  return request({
    url: '/wap/member/collect/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function onUncollect(data) {
  return request({
    url: '/wap/member/collect/cancel',
    method: 'post',
    data: param2Obj(data)
  })
}

/**
 * 关注用户
 * @param {member_id} data 
 * @returns 
 */
export function onFollow(data) {
  return request({
    url: '/wap/member/follow/add',
    method: 'post',
    data: param2Obj(data)
  })
}

export function onUnfollow(data) {
  return request({
    url: '/wap/member/follow/cancel',
    method: 'post',
    data: param2Obj(data)
  })
}

/**
 * 行为统计
 * @param {store_id/live_id/goods_id} data 
 * @returns 
 */
export function browseStore(data) {
  return request({
    url: '/wap/store/record/browseStore',
    method: 'post',
    data: param2Obj(data)
  })
}
