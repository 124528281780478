<template>
	<div class="mhome">
		<div class="mhome-wrap" ref="scrollbar">
			<div class="muser-card" @click="handleGoCart">
				<svg-icon icon-class="cart"></svg-icon>
			</div>
			<div class="mhome-swiper">
				<template v-if="goodsInfo.goods_images && goodsInfo.goods_images.length>1">
					<swiper ref="mySwiper" :options="swiperOptions">
						<swiper-slide v-for="(image,index) in goodsInfo.goods_images" :key="index">
							<img class="swiper-pic" :src="image.image_url" @error="imageLoadError" alt="" width="100%">
						</swiper-slide>
						<div class="swiper-pagination" slot="pagination"></div>
					</swiper>
				</template>
				<img v-else class="swiper-pic" :src="goodsInfo.default_image" @error="imageLoadError" alt="" width="100%">
			</div>
			<div class="mhome-main">
				<div class="section view-head">
					<div class="item-top">
						<div class="item-price">
							<div class="price">￥<span>{{ specsItem.price?specsItem.price:goodsInfo.price | priceFilter() }}</span></div>
							<div class="seckill-price" v-if="seckillGoods">{{$t('mobile.goods.seckillPrice')}}￥<span>{{seckillGoods.seckill_price}}</span></div>
						</div>
						<div>{{goodsInfo.sales}}</div>
					</div>
					<div class="name">{{goodsInfo.goods_name}}</div>
				</div>
				<div class="section view-param">
					<div class="item" @click="handlePopOpen('default')">
						<div class="item-label">{{$t('mobile.goods.text1')}}</div>
						<div class="item-block">
							<div class="specs" v-if="specsItem.spec_id">{{$t('mobile.goods.text2')+'：'}}{{specsItem.spec_1}}{{specsItem.spec_2?'/'+specsItem.spec_2:''}}</div>
							<div class="specs" v-else>{{$t('mobile.goods.text3')}}</div>
						</div>
						<div class="item-next">
							<i class="el-icon-arrow-right"></i>
						</div>
					</div>
					<div class="item">
						<div class="item-label">{{$t('mobile.goods.text4')}}</div>
						<div class="item-block">
							<div class="text">{{$t('mobile.goods.text5')+'：'}}{{parseFloat(goodsInfo.freight)>0?'￥'+goodsInfo.freight:$t('mobile.goods.text6')}}</div>
						</div>
					</div>
					<div class="item">
						<div class="item-label">{{$t('mobile.goods.text7')}}</div>
						<div class="item-block">
							<div class="text">{{$t('mobile.goods.text8')}}</div>
						</div>
					</div>
				</div>
				<div class="section view-evaluate">
					<router-link :to="{ name: 'mGoodsComment',params: $store.params }" class="section-head">
						<div class="item-label">{{$t('mobile.goods.text9')}}({{evaluateTotal}})</div>
						<div class="item-next"><span>{{$t('mobile.actions.viewAll')}}</span><i class="el-icon-arrow-right"></i></div>
					</router-link>
					<div class="evaluate-list">
						<div class="item" v-for="(item,index) in evaluateList" :key="index">
							<div class="item-head">
								<img class="pic" :src="item.portrait" @error="avatarLoadError" alt="">
								<div class="info-wrap">
									<div class="name">{{item.nick_name}}</div>
									<div class="text">{{item.create_time | parseTime('{y}-{m}-{d}')}}</div>
								</div>
							</div>
							<div class="item-text">{{item.content}}</div>
							<!-- <ul class="item-image" v-if="item.img_urls.length>0">
								<li v-for="(image, index2) in item.img_urls" :key="index2"><img :src="image.img_url" alt=""></li>
							</ul> -->
						</div>
					</div>
					<div class="section-hr"></div>
					<div class="section-head">
						<div class="item-label">{{$t('mobile.goods.text10')}}({{consultTotal}})</div>
						<div class="item-next" v-if="consultList&&consultList.length>0"><span>{{$t('mobile.actions.viewAll')}}</span><i
								class="el-icon-arrow-right"></i></div>
					</div>
					<div class="quiz-list" v-if="consultList&&consultList.length>0">
						<div class="item" v-for="(item,index) in consultList" :key="index">
							<div class="item-label">{{item.content}}</div>
							<div class="item-value">{{item.reply_num}}{{$t('mobile.goods.text11')}}</div>
						</div>
					</div>
					<div class="quiz-empty" v-else>
						<div class="item-label">{{$t('mobile.goods.text12')}}</div>
						<div class="item-btn">{{$t('mobile.goods.text13')}}</div>
					</div>
				</div>

				<div class="section view-store">
					<div class="store-info">
						<div class="info-main">
							<img :src="storeInfo.store_logo?storeInfo.store_logo:$imageErrorLogo" alt="" @error="imageShopLoadError" class="pic">
							<div class="info-box">
								<div class="name">{{storeInfo.store_name}}</div>
								<div class="text"></div>
							</div>
						</div>
						<div class="info-bar">
							<div class="item">{{$t('mobile.goods.text14')}}4.9<span>{{$t('mobile.goods.text17')}}</span></div>
							<div class="item">{{$t('mobile.goods.text15')}}4.9<span>{{$t('mobile.goods.text17')}}</span></div>
							<div class="item">{{$t('mobile.goods.text16')}}4.9<span>{{$t('mobile.goods.text17')}}</span></div>
						</div>
					</div>
					<div class="store-public">
						<div class="btn"
							@click="$router.push({ name: 'mStoreInfo', params: { id: goodsInfo.store_id }})">{{$t('mobile.actions.joinShop')}}</div>
						<div class="btn"
							@click="$router.push({ name: 'mStoreGoods', params: { id: goodsInfo.store_id }})">{{$t('mobile.actions.allGoods')}}</div>
					</div>
					<div class="section-hr"></div>
					<div class="section-head">
						<div class="item-label">{{$t('mobile.goods.text18')}}</div>
						<div @click="$router.push({ name: 'mStoreGoods', params: { id: goodsInfo.store_id }})" class="item-next"><span>{{$t('mobile.actions.viewAll')}}</span><i class="el-icon-arrow-right"></i></div>
					</div>
					<div class="store-recom">
						<router-link class="item" v-for="(item,index) in recomList" :key="index"
							:to="{name:'mGoodsInfo',params:{id:item.goodsId}}">
							<img :src="item.defaultImage" alt="" @error="imageLoadError" class="pic">
							<div class="item-main">
								<div class="name">{{item.goodsName}}</div>
								<div class="price">￥<span>{{item.price}}</span></div>
							</div>
						</router-link>
					</div>
				</div>
			</div>
			<div class="mhome-detail">
				<div class="detail-top"><span>{{$t('mobile.goods.text19')}}</span></div>
				<div class="detail-wrap">
					<div class="remark" v-if="mobileContent.remark">{{mobileContent.remark}}</div>
					<p v-for="item,index in mobileContent.list" :key="index" v-html="item"></p>
				</div>
			</div>
			<div class="mhome-block"></div>
			<div class="mhome-block h68" v-if="isSeckillStart"></div>
			<div class="mhome-bottom">
				<div class="mfooter-seckill" v-if="isSeckillStart">
					<el-tag type="danger" effect="dark" size="mini">{{$t('mobile.goods.seckill')}}</el-tag>
					<span class="text">{{$t('mobile.goods.end')}}</span>
					<span class="time">{{ seckillTimes }}</span>
				</div>
				<div class="mfooter-nav">
					<div class="footer-main">
						<div class="item"
							@click="$router.push({ name: 'mStoreInfo', params: { id: goodsInfo.store_id }})">
							<svg-icon icon-class="shop" class="item-icon icon-shop"></svg-icon>
							<div class="item-name">{{$t('mobile.goods.text20')}}</div>
						</div>
					</div>
					<div class="footer-box">
						<div class="btn btn-cart" @click="handlePopOpen('cart')">{{$t('mobile.actions.addCart')}}</div>
						<div class="btn btn-buy" @click="handlePopOpen('buy')">{{$t('mobile.actions.buyNow')}}</div>
					</div>
				</div>
			</div>
			<div class="mpop" :class="{'mpop-show':isPopShow}">
				<div class="mpop-bg" @click="handlePopClose"></div>
				<div class="mpop-body">
					<div class="mpop-close" @click="handlePopClose"><i class="el-icon-close"></i></div>
					<div class="mpop-item">
						<div class="item-pic"><img :src="specsItem.img_url?specsItem.img_url:goodsInfo.default_image"
								@error="imageLoadError" alt=""></div>
						<div class="item-main">
							<!-- <div class="name">13213132</div> -->
							<div class="item-price">
								<div class="price">￥<span>{{ specsItem.price | priceFilter() }}</span></div>
								<div class="seckill-price" v-if="seckillGoods">{{$t('mobile.goods.seckillPrice')}}￥<span>{{seckillGoods.seckill_price}}</span></div>
							</div>
						</div>
					</div>
					<div class="mpop-main">
						<div class="mpop-specs">
							<div class="item" v-for="item in Object.keys(specsList)" :key="item">
								<div class="item-label">{{item}}</div>
								<div class="item-block">
									<el-radio-group v-model="checkSpecs[item]" size="small" @change="handleSpecsChange">
										<el-radio-button v-for="specs in specsList[item]" :key="specs" :label="specs">
										</el-radio-button>
									</el-radio-group>
								</div>
							</div>
							<div class="item item-number">
								<div class="item-label">{{$t('mobile.goods.text21')}}<span>({{$t('mobile.goods.text22')}}{{specsItem.stock}}{{$t('mobile.goods.text23')}})</span></div>
								<div class="item-block">
									<div class="minput-number" v-if="specsItem.stock>0">
										<div class="number-decrease" @click="handleMinusGoods"><i
												class="el-icon-minus"></i>
										</div>
										<input class="number-inner" type="text" disabled="disabled" v-model="quantity">
										<div class="number-increase" @click="handlePlusGoods"><i
												class="el-icon-plus"></i>
										</div>
									</div>
									<div class="minput-number" v-else>
										<div class="number-decrease is-disabled"><i class="el-icon-minus"></i></div>
										<input class="number-inner" type="text" disabled="disabled" v-model="quantity">
										<div class="number-increase is-disabled"><i class="el-icon-plus"></i></div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mpop-bottom">
						<div class="mpop-bottom-main" v-if="popType==='default'">
							<div class="btn btn-cart" @click="handleConfirm('cart')">{{$t('mobile.actions.addCart')}}</div>
							<div class="btn btn-buy" @click="handleConfirm('buy')">{{$t('mobile.actions.buyNow')}}</div>
						</div>
						<div class="btn" @click="handleConfirm" v-else>{{$t('mobile.actions.confirm')}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { fetchGoods, fetchShopInfo, getGoodsRecommends, getGoodsComments, getGoodsConsults, browseStore } from '@/api/mobile/store';
	import { createCart } from '@/api/mobile/order';
	import { getToken } from '@/utils/auth';
	export default {
		data() {
			return {
				swiperOptions: {
					loop: false,
					autoplay: true,
					pagination: {
						el: '.swiper-pagination',
					},
				},
				goodsInfo: {},
				mobileContent: {},
				evaluateList: [],
				evaluateTotal: 0,
				consultList: [],
				consultTotal: 0,
				storeInfo: {},
				recomList: [],
				isPopShow: false,
				popType: 'default',
				specsList: [],
				specsItem: {},
				checkSpecs: {},
				quantity: 0,
				seckillGoods: null,
				setSeckillFunction: null,
				isSeckillStart: false,
				seckillTimes: '00:00:00'
			}
		},
		mounted() {
			this.getGoodsInfo();
			this.getEvaluateList();
			this.getConsultList();
		},
		methods: {
			imageLoadError(e) {
				e.target.src = this.$imageErrorGoods;
				e.target.style.objectFit = 'fill';
			},
			avatarLoadError(e) {
				e.target.src = this.$imageErrorUser;
				e.target.style.objectFit = 'fill';
			},
			imageShopLoadError(e){
				e.target.src = this.$imageErrorLogo;
				e.target.style.objectFit = 'fill';
			},
			getGoodsInfo() {
				let that = this;
				let loading = that.$loading();
				let param = {
					goods_id: that.$route.params.id,
					app_key: that.$store.getters.appKey
				}
				if(this.$route.query.live){
					param.live_id = this.$route.query.live;
				}
				fetchGoods(param).then(async response => {
					const { data } = response;
					this.goodsInfo = data;
					this.mobileContent = data.mobile_content ? JSON.parse(data.mobile_content) : {};
					this.seckillGoods = data.valid_goods_seckill || null
					if(this.seckillGoods) {
						setInterval(() => {
							this.setSeckillFunction = this.onSeckillGoodsTimes();
						}, 1000)
					}
					this.getSpecsList(data.goods_specs);
					this.getStoreInfo(data.store_id);
					this.getRecomList(data.store_id);
					await browseStore({goods_id: data.goods_id, store_id: data.store_id, from: 0 })
					document.title = data.goods_name;
					loading.close();
				}).catch(() => {
					loading.close();
					this.$router.go(-1);
				})
			},
			// 店铺规格
			getSpecsList(data) {
				if (data.length > 0) {
					let specArr1 = [];
					let specArr2 = [];
					data.forEach(item => {
						if (!specArr1.includes(item.spec_1) && item.spec_1) {
							specArr1.push(item.spec_1);
						}
						if (!specArr2.includes(item.spec_2) && item.spec_2) {
							specArr2.push(item.spec_2);
						}
					})
					if(specArr1.length>0){
						this.specsList['颜色'] = specArr1;
						this.checkSpecs['颜色'] = data[0].spec_1;
					}
					if(specArr2.length>0){
						this.specsList['尺码'] = specArr2;
						this.checkSpecs['尺码'] = data[0].spec_2;
					}
					this.specsItem = JSON.parse(JSON.stringify(data[0]));
				} else {
					this.specsList = [];
					this.checkSpecs = {};
					this.specsItem = JSON.parse(JSON.stringify(this.goodsInfo));
				}
				if (parseInt(this.specsItem.stock) > 0) {
					this.quantity = 1;
				} else {
					this.quantity = 0;
				}
			},
			// 店铺详情
			getStoreInfo(storeId) {
				fetchShopInfo({store_id: storeId}).then(response => {
					this.storeInfo = response.data;
				})
			},
			// 店铺推荐
			getRecomList(storeId) {
				let param = {
					store_id: storeId,
					goods_id: this.$route.params.id,
					page_index: 0,
					page_size: 6,
					app_key: this.$store.getters.appKey
				}
				getGoodsRecommends(param).then(response => {
					this.recomList = response.data.list;
				})
			},
			// 评价
			getEvaluateList() {
				let param = {
					goods_id: this.$route.params.id,
					page_index: 0,
					page_size: 2,
					app_key: this.$store.getters.appKey
				}
				getGoodsComments(param).then(response => {
					this.evaluateList = response.data.list;
					this.evaluateTotal = response.data.total;
				})
			},
			// 提问
			getConsultList() {
				let param = {
					goods_id: this.$route.params.id,
					app_key: this.$store.getters.appKey
				}
				getGoodsConsults(param).then(response => {
					this.consultList = response.data.list;
					this.consultTotal = response.data.total;
				})
			},
			handlePopOpen(type) {
				this.isPopShow = true;
				this.popType = type;
			},
			handlePopClose() {
				this.isPopShow = false;
			},
			handleSpecsChange(val) {
				let specs = JSON.parse(JSON.stringify(this.goodsInfo.goods_specs));
				let falg = true;
				specs.forEach(item => {
					if (item.spec_1 === this.checkSpecs['颜色'] && item.spec_2 === this.checkSpecs['尺码']) {
						this.specsItem = item;
						if (parseInt(this.specsItem.stock) > 0) {
							this.quantity = 1;
						} else {
							this.quantity = 0;
						}
						falg = false;
					}
				})
				// 规格不存在时
				if(falg){
					this.specsItem.price = this.goodsInfo.price;
					this.specsItem.spec_1 = this.checkSpecs['颜色'];
					this.specsItem.spec_2 = this.checkSpecs['尺码'];
					this.specsItem.stock = 0;
					this.specsItem.spec_id = 0;
					this.quantity = 0;
				}
			},
			handleMinusGoods() {
				if (this.quantity > 0) {
					this.quantity--;
				} else {
					this.quantity = 0;
				}
			},
			handlePlusGoods() {
				if (this.quantity < this.specsItem.stock) {
					this.quantity++;
				} else {
					this.quantity = parseInt(this.specsItem.stock);
				}
			},
			handleGoCart() {
				this.$router.push({
					name: 'mShoppingCart'
				});
			},
			handleConfirm(type) {
				if (this.specsList && Object.keys(this.specsList).length > 0 && !parseInt(this.specsItem.spec_id) > 0) {
					this.$message({
						message: this.$t('mobile.errorMsg.goodsSpecs'),
						customClass: 'wap-message',
						type: 'error'
					});
					return false;
				}
				if (!parseInt(this.specsItem.stock) > 0 || parseInt(this.quantity) > parseInt(this.specsItem.stock)) {
					this.$message({
						message: this.$t('mobile.errorMsg.goodsStock'),
						customClass: 'wap-message',
						type: 'error'
					});
					return false;
				}
				if (this.popType === 'cart' || type === 'cart') {
					this.onAddCart();
				} else if (this.popType === 'buy' || type === 'buy') {
					this.$router.push({
						name: "mConfirmOrder",
						query: {
							from: 'detail',
							goods_id: this.$route.params.id,
							quantity: this.quantity,
							spec_id: this.specsItem.spec_id?this.specsItem.spec_id:0,
							live_id: this.$route.query.live ? this.$route.query.live : 0,
						}
					});
				}
			},
			onAddCart() {
				const hasToken = getToken();
				if(!hasToken){
					this.$message({
						message: this.$t('mobile.errorMsg.login'),
						customClass: 'wap-message',
						type: 'warning'
					});
					window.location.href = this.$loginBasePath+'/m/?jump_url='+window.location.href;
				}
				let param = {
					goods_id: this.$route.params.id,
					quantity: this.quantity,
					store_id: this.goodsInfo.store_id,
					spec_id: this.specsItem.spec_id?this.specsItem.spec_id:0,
					app_key: this.$store.getters.appKey
				}
				createCart(param).then(() => {
					this.isPopShow = false;
					this.$message({
						message: this.$t('mobile.successMsg.addCart'),
						customClass: 'wap-message',
						type: 'success'
					});
				})
			},
			onSeckillGoodsTimes() {
				let rowDate = parseInt(new Date() / 1000);
				let longTime = 0
				if(rowDate > this.seckillGoods.start_time && rowDate < this.seckillGoods.end_time) {
					longTime = this.seckillGoods.end_time - rowDate
					this.isSeckillStart = true
				} else if (rowDate < this.seckillGoods.start_time) {
					longTime = this.seckillGoods.start_time - rowDate
					this.isSeckillStart = false
				}
				if(longTime>0){
					this.seckillTimes = this.formatTime(longTime)
				}else{
					clearInterval(this.setSeckillFunction)
					this.seckillTimes = "00:00:00";
				}
			},
			formatTime(longTime) {
				let dd = longTime / 86400;
				let longH = longTime % 86400;
				let hh = longH / 3600;
				let longM = longH % 3600;
				let mm = longM / 60;
				let ss = longM % 60;
				hh = parseInt(hh) < 10 ? '0' + parseInt(hh) : parseInt(hh);
				mm = parseInt(mm) < 10 ? '0' + parseInt(mm) : parseInt(mm);
				ss = parseInt(ss) < 10 ? '0' + parseInt(ss) : parseInt(ss);
				if (parseInt(dd) > 0) {
					return parseInt(dd) + this.$t('liveplatform.unit.day') + hh + ":" + mm + ":" + ss;
				} else {
					return  hh + ":" + mm + ":" + ss;
				}
			}
		}
	}
</script>

<style lang="scss" scoped>
	.muser-card {
		top: 0.384rem;
		right: 0.384rem;
		width: 1.088rem;
		height: 1.088rem;
		line-height: 1.088rem;
		border-radius: 50%;
		font-size: 0.64rem;
		color: #FFFFFF;
		z-index: 88;
		text-align: center;
		position: absolute;
		background: rgba(245, 78, 41, .5);
	}

	.mhome-swiper {
		height: 12rem;
		position: relative;
		background-color: #FFFFFF;

		::v-deep .swiper-container {
			height: 100%;
		}

		::v-deep .swiper-pagination {
			height: 0;
			bottom: 0.704rem;

			.swiper-pagination-bullet {
				width: 0.256rem;
				height: 0.256rem;
				background-color: #FFFFFF;
				opacity: .5;
			}

			.swiper-pagination-bullet-active {
				opacity: 1;
			}
		}

		.swiper-pic {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}
	}

	.mhome-main {
		padding: 0 0.384rem;
		margin-top: 0.32rem;

		.section {
			border-radius: 0.32rem;
			margin-bottom: 0.32rem;
			background-color: #FFFFFF;

			.section-head {
				color: #333333;
				display: flex;
				line-height: 0.576rem;
				padding: 0.256rem 0;
				justify-content: space-between;

				.item-label {
					font-size: 0.448rem;
					font-weight: bold;
				}

				.item-next {
					color: #ff5000;
					font-size: 0.384rem;
				}
			}

			.section-hr {
				border-bottom: 1px solid #E5E5E5;
				margin: 0.256rem 0;
			}
		}

		.view-head {
			padding: 0.32rem 0.384rem;
			.item-top {
				display: flex;
				justify-content: space-between;
			}

			.item-price {
				line-height: 0.832rem;
				.price {
					color: #ff5000;
					font-size: 0.384rem;
					display: inline-block;
					vertical-align: middle;
					span {
						font-size: 0.576rem;
					}
				}
				.seckill-price {
					color: #FFFFFF;
					font-size: 0.352rem;
					padding: 0 0.48rem;
					display: inline-block;
					vertical-align: middle;
					border-radius: 0.448rem;
					height: 0.832rem;
					margin-left: 0.32rem;
					background: linear-gradient(to right, #f25528, #d8243a);
					span {
						font-size: 0.48rem;
					}
				}
			}

			.name {
				font-size: .416rem;
				line-height: 0.704rem;
				margin-top: 0.192rem;
			}
		}

		.view-param {
			padding: 0.192rem 0.384rem;

			.item {
				padding: 0.256rem 0;
				display: flex;
				justify-content: center;
				line-height: 0.576rem;

				.item-label {
					width: 1.28rem;
					color: #999999;
					font-size: 0.352rem;
					margin-right: 0.256rem;
				}

				.item-block {
					flex: 1;
					width: 0;
					color: #333333;
					font-size: 0.352rem;

					.specs {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
				}

				.item-next {
					color: #999999;
					font-size: 0.448rem;
					margin-left: 0.256rem;
				}
			}
		}

		.view-evaluate {
			padding: 0.192rem 0.384rem;

			.evaluate-list {
				.item {
					padding: 0.256rem 0;
					border-bottom: 1px solid #E5E5E5;

					.item-head {
						display: flex;

						.pic {
							width: 1.024rem;
							height: 1.024rem;
							border-radius: 50%;
							object-fit: cover;
						}

						.info-wrap {
							margin-left: 0.256rem;

							.name {
								font-size: 0.384rem;
								line-height: 0.512rem;
								margin-bottom: 0.096rem;
							}

							.text {
								color: #999999;
								font-size: 0.32rem;
								line-height: 1em;
								margin-top: 0.128rem;
							}
						}
					}

					.item-text {
						font-size: 0.384rem;
						line-height: 0.48rem;
						margin-top: 0.32rem;
					}

					.item-image {
						display: flex;
						margin-top: 0.32rem;
						justify-content: space-between;

						li {
							width: 2.56rem;
							height: 2.56rem;

							img {
								width: 100%;
								height: 100%;
								border-radius: 0.16rem;
								object-fit: cover;
							}
						}
					}
				}

				.item:last-child {
					border-bottom: none;
				}
			}

			.quiz-list {
				margin-bottom: 0.256rem;

				.item {
					display: flex;
					justify-content: space-between;
					line-height: 0.768rem;
					font-size: 0.384rem;

					.item-label {
						flex: 1;
						width: 0;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}

					.item-value {
						color: #999999;
						width: 20%;
						text-align: right;
						margin-left: 0.256rem;
					}
				}
			}

			.quiz-empty {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 0.256rem;

				.item-label {
					color: #999999;
					font-size: 0.352rem;
				}

				.item-btn {
					color: #ff5000;
					height: 0.704rem;
					line-height: 0.672rem;
					border: 1px solid #ff5000;
					box-sizing: border-box;
					font-size: 0.352rem;
					padding: 0 0.256rem;
					border-radius: 0.352rem;
				}

				// text-align: center;
				// color: #999999;
				// font-size: 0.352rem;
				// line-height: 0.448rem;
				// margin-bottom: 0.256rem;
			}
		}

		.view-store {
			padding: 0.192rem 0.384rem;

			.store-info {
				display: flex;
				padding: 0.32rem 0;

				.info-main {
					display: flex;
					flex: 1;
					width: 0;

					.pic {
						width: 1.536rem;
						height: 1.536rem;
						border-radius: 0.16rem;
						object-fit: cover;
						margin-right: 0.256rem;
					}

					.info-box {
						flex: 1;
						width: 0;

						.name {
							font-size: 0.448rem;
							font-weight: bold;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}

				.info-bar {
					width: 30%;
					margin-left: 0.32rem;
					text-align: right;

					.item {
						font-size: 0.32rem;
						line-height: 0.512rem;

						span {
							color: #999999;
							margin-left: 0.192rem;
						}
					}
				}
			}

			.store-public {
				text-align: center;
				padding-bottom: 0.256rem;

				.btn {
					color: #ff5000;
					font-size: 0.352rem;
					line-height: 1em;
					padding: 0.224rem 0.32rem;
					display: inline-block;
					margin: 0 0.32rem;
					border-radius: 0.48rem;
					border: 1px solid #ff5000;
				}
			}

			.store-recom {
				overflow: hidden;
				margin-top: 0.256rem;

				.item {
					float: left;
					width: 3.328rem;
					margin-right: 0.24rem;
					margin-bottom: 0.224rem;

					.pic {
						width: 3.328rem;
						height: 3.328rem;
						border-radius: 0.192rem;
					}
				}

				.item:nth-child(3n) {
					margin-right: 0;
				}

				.item-main {
					padding: 0.192rem 0;

					.name {
						font-size: 0.416rem;
						height: 0.544rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						line-height: 0.544rem;
					}

					.price {
						margin-top: 0.128rem;
						color: #FF5000;
						font-size: 0.32rem;

						span {
							font-size: 0.416rem;
						}
					}
				}
			}
		}
	}

	.mhome-detail {
		.detail-top {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.192rem 0;
			margin-bottom: 0.256rem;

			span {
				color: #333333;
				font-size: 0.416rem;
				margin: 0 0.256rem;
			}
		}

		.detail-top:before,
		.detail-top:after {
			content: "";
			width: 1.6rem;
			height: 1px;
			background-color: #E5E5E5;
		}

		.detail-wrap {
			background-color: #FFFFFF;
			font-size: 0.416rem;
			line-height: 1.5em;
			padding: 0.256rem 0;

			.remark {
				font-size: 0.48rem;
				line-height: 0.768rem;
			}
		}
	}

	.mhome-block {
		height: 1.472rem;
		&.h68 {
			height: 1.088rem;
		}
	}

	.mfooter-nav {
		display: flex;
		justify-content: space-between;
		background-color: #FFFFFF;
		box-shadow: 0 -1px 1px #E5E5E5;

		.footer-main {
			display: flex;

			.item {
				width: 1.6rem;
				text-align: center;

				.item-icon {
					height: 1.024rem;
					line-height: 1.024rem;
					margin: 0 auto;
					font-size: 0.832rem;

					&.icon-shop {
						color: #ff5000
					}
				}

				.item-name {
					font-size: 0.352rem;
					line-height: 1em;
					color: #777777;
				}
			}
		}

		.footer-box {
			overflow: hidden;
			padding: 0.16rem 0.384rem;

			.btn {
				height: 1.152rem;
				line-height: 1.152rem;
				width: 3.2rem;
				text-align: center;
				font-size: 0.416rem;
				display: inline-block;
				vertical-align: top;
				border-radius: 0.576rem;
			}

			.btn-cart {
				color: #FFFFFF;
				border-radius: 0.576rem 0 0 0.576rem;
				background: linear-gradient(to right, #f3cc40, #f6993c);
			}

			.btn-buy {
				color: #FFFFFF;
				border-radius: 0 0.576rem 0.576rem 0;
				background: linear-gradient(to right, #ee7e32, #f25528);
			}
		}
	}
	.mfooter-seckill {
		padding: 0.192rem 0.384rem;
		display: flex;
		align-items: center;
		background: linear-gradient(to right, #d8243a, #f25528);
		box-shadow: 0 -1px 1px #E5E5E5;
		.el-tag {
			height: 0.64rem;
			color: #d8243a;
			line-height: 0.64rem;
			padding: 0 0.192rem;
			font-size: 0.384rem;
			font-weight: bold;
			border-color: #FFFFFF;
			background-color: #FFFFFF;
		}
		.text {
			color: #FFFFFF;
			opacity: 0.8;
			font-size: 0.384rem;
			margin-left: 0.32rem;
		}
		.time {
			font-size: 0.384rem;
			color: #FFFFFF;
			margin-left: 0.32rem;
		}
	}

	.mpop-item {
		padding: 0.384rem;

		.item-pic {
			float: left;
			width: 1.92rem;
			height: 1.92rem;

			img {
				width: 100%;
				height: 100%;
				border-radius: 0.128rem;
				object-fit: cover;
			}
		}

		.item-main {
			min-height: 1.92rem;
			margin-left: 2.24rem;
			padding-right: 1.28rem;

			.name {
				height: 0.768rem;
				line-height: 0.768rem;
				font-size: 0.448rem;
				overflow: hidden;
				text-overflow: ellipsis;
			}
			.item-price {
				.price {
					// margin-top: 0.128rem;
					color: #FF5000;
					font-size: 0.384rem;
					height: 0.768rem;
					line-height: 0.768rem;
					display: inline-block;
					vertical-align: middle;

					span {
						font-size: 0.576rem;
						font-weight: bold;
					}
				}
				.seckill-price {
					color: #FFFFFF;
					font-size: 0.352rem;
					padding: 0 0.48rem;
					display: inline-block;
					vertical-align: middle;
					border-radius: 0.448rem;
					height: 0.768rem;
					line-height: 0.768rem;
					margin-left: 0.32rem;
					background: linear-gradient(to right, #f25528, #d8243a);
					span {
						font-size: 0.48rem;
					}
				}
			}
		}
	}

	.mpop-main {
		max-height: 8.96rem;
		overflow-y: auto;
	}

	.mpop-bottom {
		.btn-cart {
			color: #FFFFFF;
			border-radius: 0.576rem 0 0 0.576rem;
			background: linear-gradient(to right, #f3cc40, #f6993c);
		}

		.btn-buy {
			color: #FFFFFF;
			border-radius: 0 0.576rem 0.576rem 0;
			background: linear-gradient(to right, #ee7e32, #f25528);
		}

		.mpop-bottom-main {
			display: flex;

			.btn {
				width: 50%;
				flex-shrink: 0;
			}
		}
	}

	.mpop-specs {
		padding: 0 0.384rem;
		max-width: 12.288rem;
		box-sizing: border-box;

		.item {
			padding: 0.256rem 0;
			border-bottom: 1px solid #E5E5E5;

			.item-label {
				padding: 0.192rem 0;
				font-size: 0.448rem;
				line-height: 0.64rem;

				span {
					color: #999999;
				}
			}

			.item-block {
				::v-deep .el-radio-group {
					margin-top: -0.256rem;

					.el-radio-button {
						margin-right: 0.256rem;
						margin-top: 0.256rem;

						.el-radio-button__inner {
							font-size: 0.416rem;
							line-height: 1em;
							padding: 0.272rem 0.32rem;
							border-radius: 0.128rem;
							border-left: 1px solid #DCDFE6;
						}

						.el-radio-button__orig-radio:checked+.el-radio-button__inner {
							color: #f25528;
							border-color: #f25528;
							background-color: transparent;
							box-shadow: none;
						}
					}
				}

				.minput-number {
					height: 1.024rem;
					line-height: 1.024rem;
					box-sizing: border-box;
					border: 1px solid #DCDFE6;
					border-radius: 0.128rem;
					overflow: hidden;

					.number-decrease,
					.number-increase {
						color: #606266;
						text-align: center;
						font-size: 0.416rem;
						height: 100%;
						width: 1.024rem;
						display: inline-block;
						vertical-align: top;
						background-color: #F5F7FA;
					}

					.number-decrease {
						border-right: 1px solid #DCDFE6;
					}

					.number-increase {
						border-left: 1px solid #DCDFE6;
					}

					.number-decrease.is-disabled,
					.number-increase.is-disabled {
						color: #c0c4cc;
					}

					.number-inner {
						height: 100%;
						width: 2.08rem;
						display: inline-block;
						vertical-align: top;
						text-align: center;
						font-size: 0.416rem;
						padding: 0 0.224rem;
						box-sizing: border-box;
					}
				}
			}
		}

		.item:last-child {
			border-bottom: none;
		}

		.item-number {
			display: flex;
			justify-content: space-between;
		}
	}
</style>
